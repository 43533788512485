import * as React from 'react';
import styled from '@emotion/styled';
import { useHighlightHandlers } from '../../contexts/HighlightContext/Highlight';
import { Typography as MuiTypography } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import { getText } from '../../utils/get-text';

export interface CardContentProps {
  title?: string | undefined;
  description?: string | undefined;
  limit?: number | undefined;
  className?: string;
}

const CardContentStyled = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  paddingTop: theme.spacing(1),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  '&:last-child': {
    paddingBottom: theme.spacing(1),
  },
})) as typeof CardContent;

const Typography = React.forwardRef<HTMLInputElement, any>((props, ref) =>
  useHighlightHandlers(MuiTypography, { forwardedRef: ref, ...props })
);

// Todo: HUZ2ERA-1101 - Allow Typography props to be drilled. Had trouble extending our CardContentAProps...
export const CardContentA = (props: CardContentProps) => {
  const { title, limit, className, ...other } = props;
  const captionRef = React.useRef<HTMLInputElement | null>(null);

  return (
    <>
      {title && (
        <CardContentStyled className={className}>
          <Typography
            ref={captionRef}
            sx={{ width: '100%', display: 'inline-block' }}
            variant="caption"
            component="span"
            noWrap
            {...other}
          >
            {getText(title, limit || 35)}
          </Typography>
        </CardContentStyled>
      )}
    </>
  );
};
