import * as React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'next-i18next';
import { Typography as MuiTypography, Button as MuiButton, CardContent } from '@mui/material';
import { useHighlightHandlers } from '../../contexts/HighlightContext/Highlight';
import { getText } from '../../utils/get-text';

export interface CardContentHProps {
  title?: string;
  description?: string;
  hideGameTitle?: boolean;
  hideDescription?: boolean;
  hidePlayButton?: boolean;
  limit?: number;
  descriptionLimit?: number;
  className?: string;
  hoverActive?: boolean;
  cardColor?: string;
}

const Button = React.forwardRef<HTMLButtonElement, any>((props, ref) =>
  useHighlightHandlers(MuiButton, { forwardedRef: ref, ...props })
);

const Typography = React.forwardRef<HTMLInputElement, any>((props, ref) =>
  useHighlightHandlers(MuiTypography, { forwardedRef: ref, ...props })
);

// Play button overlay that goes on top of the image
const PlayButtonContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: -12,
  left: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 10,
  pointerEvents: 'none',
}));

const PlayButton = styled(Button)(({ theme }) => ({
  minWidth: '110px',
  backgroundColor: theme.theme_component_carousel_layout_button_background || '#E10000',
  color: theme.theme_component_carousel_layout_button_color || '#FFFFFF',
  padding: '4px 24px',
  height: '24px',
  fontSize: theme.typography.htmlFontSize || '12px',
  fontWeight: theme.typography.h1.fontWeight || 700,
  borderRadius: '8px',
  textTransform: 'uppercase',
  pointerEvents: 'auto',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
  '&:hover': {
    backgroundColor: theme.theme_component_carousel_layout_button_background
      ? theme.theme_component_carousel_layout_button_background
      : '#b80000',
  },
}));

// Content container (title and description)
const Container = styled(CardContent)(({ theme, hoverActive }: any) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  position: 'relative',
  backgroundColor: theme.theme_component_card_background_color || '#FFFFFF',
  borderRadius: '0 0 8px 8px',
  '&:hover': hoverActive && {
    backgroundColor: theme.theme_component_card_hover_background_color || '#FFFFFF',
  },
}));

const TitleText = styled(Typography)(({ theme, hideDescription }: any) => ({
  width: '100%',
  fontWeight: theme.typography.h1.fontWeight || 700,
  marginTop: hideDescription ? theme.spacing(3) : theme.spacing(1),
  fontSize: theme.typography.h1.fontSize || '19px',
  lineHeight: theme.typography.h1.lineHeight || '18px',
  textAlign: 'center',
  color: theme.theme_component_title_color || '#000000',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
}));

const DescriptionText = styled(Typography)(({ theme, hideGameTitle }: any) => ({
  width: '100%',
  marginTop: hideGameTitle ? theme.spacing(2) : theme.spacing(1),
  fontWeight: 400, // theme.typography.h2.fontWeight || 400,
  fontSize: hideGameTitle ? '14px' : '12px',
  lineHeight: hideGameTitle ? '18px' : '14px',
  textAlign: 'center',
  color: theme.theme_component_game_description_font_color || '#666666',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
  minHeight: '14px',
}));

const PlaceholderText = styled('div')(() => ({
  width: '80%',
  height: '100%',
  backgroundColor: 'transparent',
  opacity: 0,
}));

export const CardContentH = (props: CardContentHProps) => {
  const {
    title,
    description,
    hideGameTitle = false,
    hideDescription = false,
    hidePlayButton = false,
    hoverActive = true,
    limit,
    className,
  } = props;
  const { t } = useTranslation('card_content_h');

  return (
    <>
      <Container className={className} hoverActive={hoverActive}>
        {!hidePlayButton && (
          <PlayButtonContainer>
            <PlayButton variant="contained" disableElevation size="small">
              {t('play_now', 'Play')}
            </PlayButton>
          </PlayButtonContainer>
        )}

        {!hideGameTitle && (
          <TitleText variant="body1" hideDescription={hideDescription}>
            {title ? getText(title, limit || 35) : <PlaceholderText style={{ height: '14px' }} />}
          </TitleText>
        )}

        {!hideDescription && (
          <DescriptionText variant="body2" hideGameTitle={hideGameTitle}>
            {description ? getText(description, limit || 60) : <PlaceholderText style={{ height: '10px' }} />}
          </DescriptionText>
        )}
      </Container>
    </>
  );
};
