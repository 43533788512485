import * as React from 'react';
import styled from '@emotion/styled';
import { useHighlightHandlers } from '../../contexts/HighlightContext/Highlight';
import { Typography as MuiTypography, Button as MuiButton } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import { useTranslation } from 'next-i18next';
import { getText } from '../../utils/get-text';

export interface CardContentProps {
  title?: string | undefined;
  description?: string | undefined;
  limit?: number | undefined;
  className?: string;
}

const Button = React.forwardRef<HTMLButtonElement, any>((props, ref) =>
  useHighlightHandlers(MuiButton, { forwardedRef: ref, ...props })
);

const CardContentStyled = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  textAlign: 'left',
  width: '100%',
  height: '100%',
  paddingTop: theme.spacing(1),
  paddingRight: theme.spacing(0),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(0),
  '&:last-child': {
    paddingBottom: theme.spacing(1),
  },
})) as typeof CardContent;

const CardWrapper = styled(Button)(({ theme }) => ({
  padding: theme.spacing(0, 5),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '30%',
  [theme.breakpoints.down('md')]: {
    alignItems: 'normal',
    width: 'auto',
  },
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
  display: 'inline-block',
  width: '104px',
  height: '40px',
  margin: 0,
  padding: theme.spacing(0),
  borderRadius: '8px',
  border: '1px solid #B1B1B1',
  marginLeft: theme.spacing(0),
  marginTop: theme.spacing(1),
  fontSize: '16px',
  fontWeight: 'bold',
  color: theme.theme_component_card_content_button_color || theme.palette.primary.contrastText,
  background: theme.theme_component_card_content_button_background || theme.palette.primary.main,
  textTransform: 'none',
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(3),
  },
  '&:hover': {
    backgroundColor: '#cc0100',
  },
}));

const Typography = React.forwardRef<HTMLInputElement, any>((props, ref) =>
  useHighlightHandlers(MuiTypography, { forwardedRef: ref, ...props })
);

const DescriptionStyled = React.forwardRef<HTMLSpanElement, any>((props, ref) =>
  useHighlightHandlers(
    styled(MuiTypography)`
      font-size: 16px;
      text-transform: none;
      white-space: normal;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 7;
      display: -webkit-box;
      color: #6e6e6e;
    `,
    { forwardedRef: ref, ...props }
  )
);

export const CardContentBanner = (props: CardContentProps) => {
  const { title, description, limit, className, ...other } = props;
  const captionRef = React.useRef<HTMLInputElement | null>(null);
  const buttonRef = React.useRef<HTMLButtonElement | null>(null);
  const { t } = useTranslation('card_content_banner');

  return (
    <>
      {title && (
        <CardWrapper>
          <CardContentStyled className={className}>
            <Typography
              ref={captionRef}
              sx={{ width: '100%', display: 'inline-block', fontWeight: 700, fontSize: '20px', color: '#0C0C0C' }}
              variant="caption"
              component="span"
              noWrap
              {...other}
            >
              {getText(title, limit || 35)}
            </Typography>
          </CardContentStyled>
          <CardContentStyled className={className}>
            <DescriptionStyled
              ref={captionRef}
              variant="caption"
              color="textPrimary"
              component="span"
              noWrap
              {...other}
            >
              {description}
            </DescriptionStyled>
          </CardContentStyled>
          <ButtonStyled
            ref={buttonRef}
            variant="contained"
            disableElevation
            disableRipple
            disableFocusRipple
            size="small"
          >
            {t('play_now', 'Play')}
          </ButtonStyled>
        </CardWrapper>
      )}
    </>
  );
};
